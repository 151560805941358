import { UserService } from "@/common/api.service";
import { FETCH_USERS, DELETE_USER,DELETE_CALENDAR_ADMIN, ADD_USER, UPDATE_USER,FETCH_INDIVIDUAL_USER,FETCH_CALENDAR_USERS, FETCH_CALENDAR_ADMINS, USER_RESET_STATE, UPDATE_USER_STATUS } from "./actions.type";
import { SET_USERS,SET_CALENDAR_USERS, SET_INDIVIDUAL_USER,SET_USER_ERROR,RESET_USERS_STATE, SET_USER_STATUS } from "./mutations.type";

const initialState = () =>{
    return {    
        users: [],
        calendarusers: [],
        individualuser:[],
        usererrors: null,
    }
};

export const state = initialState();

const getters = {
    users(state) {
        return state.users;
    },
    calendarusers(state) {
        return state.calendarusers;
    },
    individualuser(state) {
        return state.individualuser;
    }    
};

const actions = {
    async [FETCH_USERS](context) {
        const { data } = await UserService.get();
        context.commit(SET_USERS, data.data);
        return data;
    },  
    async [FETCH_INDIVIDUAL_USER](context,slug) {
        const { data } = await UserService.get(slug);
        context.commit(SET_INDIVIDUAL_USER, data.data);
        return data;
    },       
    async [ADD_USER](context, payload) {
        return await UserService.create(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_USER_ERROR, error.response.data.errors);
        });
    },    
    async [UPDATE_USER](context, payload) {
        return await UserService.update(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_USER_ERROR, error.response.data.errors);
        });
    }, 
    async [UPDATE_USER_STATUS] (context, payload) {
        const { data } = await UserService.update(payload)
        context.commit(SET_USER_STATUS, data.data)
        return data;         
    },
    async [FETCH_CALENDAR_USERS](context) {
        const { data } = await UserService.fetch();
        context.commit(SET_USERS, data.data);
        return data;
    },
    async [FETCH_CALENDAR_ADMINS](context) {
        const { data } = await UserService.fetch();
        context.commit(SET_CALENDAR_USERS, data.data);
        return data;
    },
    async [DELETE_USER] (context, slug) {
        return await UserService.delete(slug)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_USER_ERROR, error.response.data.errors);
        });
    },  
    async [DELETE_CALENDAR_ADMIN] (context, slug) {
        return await UserService.deleteCalUser(slug)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_USER_ERROR, error.response.data.errors);
        });
    },  
    [USER_RESET_STATE]({ commit }) {
        commit(RESET_USERS_STATE);
    }    
};

const mutations = {
    [SET_USER_ERROR](state, error) {
        state.usererrors = error;
    },      
    [SET_USERS](state, users) {
        state.users = users;
    },
    [SET_CALENDAR_USERS](state, calendarusers) {
        state.calendarusers = calendarusers;
    },
    [SET_INDIVIDUAL_USER](state, users) {
        state.individualuser = users;
    },  
    [SET_USER_STATUS] (state,user){
        let id = state.users.findIndex(x => x.user_id === user.user_id)
        state.users[id] = user
    },    
    [RESET_USERS_STATE](state) {
        Object.assign(state, initialState())
    }    
};

export default {
    state,
    getters,
    actions,
    mutations
};