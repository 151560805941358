import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import vuexI18n from 'vuex-i18n'

import auth from './auth.module'
import modules from './modules.module'
import authors from "./authors.module"
import stripe from "./stripe.module"
import globals from "./globals.module"
import company from "./company.module"
import pages from "./pages.module"
import languages from "./languages.module"
import documents from "./documents.module"
import courses from './courses.module'
import tutorproducts from './tutorproducts.module'
import videotutorial from './tutorialvideos.module'
import sectionheaders from './sectionheaders.module'
import certificationtemplates from './certificatetemplate.module'
import teams from "./teams.module"
import xapireport from "./xapireport.module"
import users from "./users.module"
import questions from "./questions.module"
import surveys from "./survey.module"
import translations from "./translations.module"
import testexamquestions from "./testexamquestions.module"
import testexams from "./testexams.module"
import questiontopics from "./questiontopics.module"
import exercises from "./exercises.module"

import translationsEn from '../locale/en'
import translationsDe from '../locale/de'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    auth,
    authors,
    stripe,
    pages,
    sectionheaders,
    videotutorial,
    globals,
    translations,
    company,
    courses,
    tutorproducts,
    modules,
    documents,
    languages,
    certificationtemplates,
    teams,
    xapireport,
    users,
    questions,
    surveys,
    testexamquestions,
    testexams,
    questiontopics,
    exercises,
  }
})

Vue.use(vuexI18n.plugin, store);
Vue.i18n.add('en', translationsEn)
Vue.i18n.add('de', translationsDe)
export default store