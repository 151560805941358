import { CoursesService, CoursesConfigService } from "@/common/api.service";
import { FETCH_COURSES, FETCH_INDIVIDUAL_COURSE, ADD_COURSE, UPDATE_COURSE, COURSE_RESET_STATE, ADD_COURSE_VERSIONS, UPDATE_COURSE_VERSION, FETCH_COURSE_VERSIONS, FETCH_INDIVIDUAL_COURSE_CONFIG } from "./actions.type";
import { SET_COURSES, SET_COURSE_ERROR, RESET_COURSE_STATE, SET_INDIVIDUAL_COURSE, SET_COURSE_CONFIGS, SET_INDIVIDUAL_COURSE_CONFIG} from "./mutations.type";

const initialState = () => {
    return {
        courses: [],
        courseconfigs: [],
        individualcourse:{},
        individualcourseconfig:{},
        courseerrors: {},
    }
}

let state = initialState()

const getters = {
    courses(state) {
        return state.courses
    },
    courseconfigs(state) {
        return state.courseconfigs
    },
    individualcourse(state) {
        return state.individualcourse
    },
    individualcourseconfig(state) {
        return state.individualcourseconfig
    },
}

const actions = {
    async [FETCH_COURSES](context, slug) {
        const { data } = await CoursesService.get(slug)
        context.commit(SET_COURSES, data.data)
        return data;
    },
    async [FETCH_INDIVIDUAL_COURSE](context, slug) {
        const { data } = await CoursesService.get(slug)
        context.commit(SET_INDIVIDUAL_COURSE, data.data)
        return data;
    },    
    async [UPDATE_COURSE](context, payload) {
        return await CoursesService.update(payload)
        .then(({ data }) => {
          context.commit(SET_INDIVIDUAL_COURSE, data.data)  
          return data
        })
        .catch(error => {
          context.commit(SET_COURSE_ERROR, error.response.data.errors);
        });
    }, 
    async [ADD_COURSE](context, params) {
        return await CoursesService.create(params)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                context.commit(SET_COURSE_ERROR, error.response.data.errors);
            });
    },
    // async [DELETE_COURSE](context, slug) {
    //     return await CoursesService.delete(slug)
    //         .then(({ data }) => {
    //             return data
    //         })
    //         .catch(error => {
    //             context.commit(SET_COURSE_ERROR, error.response.data)
    //         });
    // },
    async [FETCH_COURSE_VERSIONS](context, slug) {
        const { data } = await CoursesConfigService.get(slug)
        context.commit(SET_COURSE_CONFIGS, data.data)
        return data;
    },
    async [ADD_COURSE_VERSIONS](context, params) {
        return await CoursesConfigService.create(params)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                context.commit(SET_COURSE_ERROR, error.response.data.errors);
            });
    },
    async [UPDATE_COURSE_VERSION](context, payload) {
        return await CoursesConfigService.update(payload)
        .then(({ data }) => {
          context.commit(SET_INDIVIDUAL_COURSE_CONFIG, data.data)  
          return data
        })
        .catch(error => {
          context.commit(SET_COURSE_ERROR, error.response.data.errors);
        });
    }, 
    async [FETCH_INDIVIDUAL_COURSE_CONFIG](context, slug) {
        const { data } = await CoursesConfigService.get(slug)
        context.commit(SET_INDIVIDUAL_COURSE_CONFIG, data.data)
        return data;
    },    
    [COURSE_RESET_STATE]({ commit }) {
        commit(RESET_COURSE_STATE);
    }
}

const mutations = {
    [SET_COURSE_ERROR](state, error) {
        state.courseerrors = error;
    },
    [SET_INDIVIDUAL_COURSE](state, coursedata) {
        state.individualcourse = coursedata
    },
    [SET_COURSES](state, coursedata) {
        state.courses = coursedata;
    },
    [SET_COURSE_CONFIGS](state, configdata) {
        state.courseconfigs = configdata;
    },
    [SET_INDIVIDUAL_COURSE_CONFIG](state, coursedata) {
        state.individualcourseconfig = coursedata
    },
    [RESET_COURSE_STATE](state) {
        Object.assign(state, initialState())
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

