<template>
  <v-container fluid>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <v-app-bar-nav-icon @click.stop="mini = !mini" />
        <span class="hidden-sm-and-down">{{ 'LMS Admin'+' - '+version }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      
      <v-toolbar-items>
        <v-form id="langform" ref="langform">
        <v-select
          @change="changedLanguage"
          v-model="selectedLanguage.lang_code"
          :items="languagesdata"
          item-value="lang_code"
          :item-text="'lang_name' | translate"
          :label="'Language' | translate"
          outlined
          dense
          class="pt-2 d-none d-sm-flex"
          style="width:140px;"
        ></v-select>
        </v-form>
      </v-toolbar-items>
        
      <v-menu offset-y bottom origin="center center" transition="scale-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon large v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in dropdown" :key="item.text+index" @click="logout">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text | translate}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" permanent :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-list dense>
        <template v-for="item in items">
          <v-list-group
            v-if="item.children"
            :key="item.text"
            v-model="item.model"
          >
            <template v-slot:activator>
              <v-list-item style="padding: 0px;">
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{item.text|translate}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item v-for="(child, i) in item.children" :to="child.link" :key="child.text+i" link>
              <v-list-item-action v-if="child.icon">
                <v-icon small :style='(mini == false ? "margin-left: 20px;" : "")'>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text | translate}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" :to="item.link" link>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.text | translate}}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import Vue from "vue";
import {
  UPDATE_USER_STATUS,
  UPDATE_CUR_USER_LANG,
  PAGES_RESET_STATE
} from "@/store/actions.type";
import {
  SET_CUR_LANGUAGE,
  SET_LOADING,
} from "@/store/mutations.type";
import { mapGetters } from "vuex";
export default{
  name: "Navbar",
  props: {
    source: String,
    version: String
  },
  data: () => ({
    dialog: false,
    drawer: true,
    mini: true,
    selectedLanguage: "",
    enable_payments : true,
    items: [],
    dropdown: [{ icon: "mdi-power", text: "Logout" }]
  }),
  computed: {
    ...mapGetters([
      "languages",
      "currentUser",
      'curLanguage',
    ]),
    languageLabel(){
      return this.$t('Language')
    },
    languagesdata(){
      return this.languages.map(data =>{
        return{
         'lang_code': data.lang_code,
         'lang_id':  data.lang_id,
         'lang_name': this.$t(data.lang_name),
        }
     
      })
    },
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated;
    }
  },
  created(){
    this.setLanguageDropdown()
    this.setSidebarMenu()
  },
  watch :{
    languages(value){
      if(Object.keys(value).length>0){
        this.setLanguageDropdown();
      }
    },
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
      .then(() => {
        this.$router.push('/login')
      })
    },
    setSidebarMenu(){
      this.enable_payments  = process.env.VUE_APP_ENABLE_PAYMENTS=='false' ? false : true ; // By default if variable is not set, payments will be shown on side menu
       this.items.push(
          { icon: "mdi-home", text: "Dashboard", link: "/dashboard" },
          { icon: "mdi-school", text: "Tutor Products", link: "/tutor-products" },
          { icon: "mdi-view-module", text: "Courses", link: "/courses" },
          { icon: "mdi-book", text: "Modules", link: "/modules" },
          { icon: "mdi-city", text: "Company", link: "/company" },
          {
            icon: 'mdi-human',
            text: 'Entities',
            model: false,
            children: [
              { icon: "mdi-account-box", text: "Authors", link: "/authors" },
              { icon: "mdi-account-multiple", text: "Teams", link: "/teams" },
              { icon: "mdi-account", text: "Admins", link: "/admins" },
              { icon: "mdi-calendar", text: "Calendar Admins", link: "/calendaradmins" },
            ],
          },

          { icon: "mdi-page-layout-body", text: "Pages", link: "/pages" },
          { icon: "mdi-file-video", text: "Video tutorial", link: "/video-tutorial" },
          {
            icon: 'mdi-file-document',
            text: 'Documents',
            model: false,
            children: [
              { icon:'mdi-library', text: 'General documents', link: '/general-documents' },
              { icon:'mdi-certificate', text: 'Certifications', link: '/certifications' },
            ],
          },
          {
            icon: 'mdi-clipboard-multiple',
            text: 'Test Center',
            model: false,
            children: [
              { icon:'mdi-help', text: 'Questions', link: '/testQuestions' },
              { icon:'mdi-note-outline', text: 'Tests & Exams', link: '/tests-exams' },
              { icon:'mdi-fountain-pen-tip', text: 'Exercises', link: '/exercises' },
            ],
          },
          {
            icon: 'mdi-clipboard-list',
            text: 'Survey',
            model: false,
            children: [
              { icon:'mdi-help', text: 'Questions', link: '/questions' },
              { icon:'mdi-frequently-asked-questions', text: 'Surveys', link: '/surveys' },
            ],
          },
          { icon: "mdi-chart-bell-curve", text: "Reporting", link: "/reporting-dashboard" },
        );

        if(this.enable_payments){
          this.items.push({ icon: "mdi-cash", text: "Payments", link: "/payments" });
        }
    },
    async setLanguageDropdown(){
      this.currentUser.lang_code==="de" ? 
      this.selectedLanguage = { "lang_id": 2, "lang_name":"German", "lang_code":"de"}
      : this.selectedLanguage = { "lang_id": 1, "lang_name":"English", "lang_code":"en"}

      this.setLanguage(this.selectedLanguage)
      Vue.i18n.set(this.curLanguage.lang_code)  
    },
    async setLanguage(language) {
      await this.$store.commit(SET_CUR_LANGUAGE, language);
    },
    async changedLanguage(value) {
        var formData = new FormData(document.getElementById("langform"));
        formData.set("user_id", this.currentUser.user_id);
        formData.set("name", this.currentUser.name);
        formData.set("email", this.currentUser.email);
        formData.set("salutation", this.currentUser.salutation);
        formData.set("phone_no", this.currentUser.phone_no);
        formData.set("lang_code", this.selectedLanguage.lang_code);
        formData.set("_method", "put");
           await this.$store
          .dispatch(UPDATE_USER_STATUS,formData)
          .then(resp => {
            if (resp != undefined && resp.hasOwnProperty('success') && resp.success) {
              //change current logged in User data to new fetched data, as we now make switcher act as user preffered langugae
              this.$store.dispatch(UPDATE_CUR_USER_LANG, {
                user_id: this.currentUser.user_id,
                lang_code: this.selectedLanguage.lang_code,        
              })
              this.setLanguageDropdown();
        
              this.$store.commit(SET_LOADING, false)
            }
          }); 
        
          Vue.i18n.set(value);
          let selectedLanguage = this.languages.find(x => value === x.lang_code);
          await this.$store.commit(SET_CUR_LANGUAGE, selectedLanguage);
          await this.$store.dispatch(PAGES_RESET_STATE);

  
    },
    
  },   
};
</script>