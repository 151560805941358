import { GeneralDocumentsService, PersonalDocumentsService } from "@/common/api.service";
import { ADD_GENERAL_DOCUMENT, FETCH_COURSE_DOCUMENTS, DOWNLOAD_GENERAL_DOCUMENT, DOWNLOAD_PERSONAL_DOCUMENT,UPDATE_GENERAL_DOCUMENT, ADD_PERSONAL_DOCUMENT, UPDATE_PERSONAL_DOCUMENT,FETCH_GENERAL_DOCUMENTS, FETCH_INDIVIDUAL_GENERAL_DOCUMENT, FETCH_INDIVIDUAL_PERSONAL_DOCUMENT, FETCH_PERSONAL_DOCUMENTS, DELETE_GENERAL_DOCUMENT, DELETE_PERSONAL_DOCUMENT, RESTORE_GENERAL_DOCUMENT, DOCUMENTS_RESET_STATE } from "./actions.type";
import { SET_GENERAL_DOCUMENTS, SET_COURSE_DOCUMENTS ,SET_PERSONAL_DOCUMENTS, SET_INDIVIDUAL_GENERAL_DOCUMENT, SET_INDIVIDUAL_PERSONAL_DOCUMENT,SET_DOCUMENTS_ERROR, RESET_DOCUMENTS_STATE } from "./mutations.type";

const initialState = () =>{
    return {
        coursedocuments:[],
        generaldocuments: [],
        individualgeneraldocument:[],
        personaldocuments: [],
        individualpersonaldocument:[],
        documenterrors: null,
    }
} ;

let state = initialState()

const getters = {
    coursedocuments(state) {
        return state.coursedocuments
    },    
    generaldocuments(state) {
        return state.generaldocuments
    },
    personaldocuments(state) {
        return state.personaldocuments
    }, 
    individualgeneraldocument(state) {
        return state.individualgeneraldocument
    },     
    individualpersonaldocument(state) {
        return state.individualpersonaldocument
    },   
    
};

const actions = {
    async [FETCH_COURSE_DOCUMENTS](context, gendocid) {
        const { data }  = await GeneralDocumentsService.get(gendocid);
        context.commit(SET_COURSE_DOCUMENTS, data.data);
        return data;
    },
    async [FETCH_GENERAL_DOCUMENTS](context, gendocid) {
        const { data }  = await GeneralDocumentsService.get(gendocid);
        context.commit(SET_GENERAL_DOCUMENTS, data.data);
        return data;
    },    
    async [FETCH_PERSONAL_DOCUMENTS](context, perdocid) {
        const { data }  = await PersonalDocumentsService.get(perdocid);
        context.commit(SET_PERSONAL_DOCUMENTS, data.data);
        return data;
    },
    async [FETCH_INDIVIDUAL_GENERAL_DOCUMENT](context, gendocid) {
        const { data }  = await GeneralDocumentsService.get(gendocid);
        context.commit(SET_INDIVIDUAL_GENERAL_DOCUMENT, data.data);
        return data;
    },
    async [FETCH_INDIVIDUAL_PERSONAL_DOCUMENT](context, perdocid) {
        const { data }  = await PersonalDocumentsService.get(perdocid);
        context.commit(SET_INDIVIDUAL_PERSONAL_DOCUMENT, data.data);
        return data;
    },


    async [ADD_GENERAL_DOCUMENT](context, params) {
        return await GeneralDocumentsService.create(params)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                context.commit(SET_DOCUMENTS_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
            });
    },
    async [UPDATE_GENERAL_DOCUMENT](context, payload) {
        return await GeneralDocumentsService.update(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
            context.commit(SET_DOCUMENTS_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
        });
    }, 
    async [ADD_PERSONAL_DOCUMENT](context, params) {
        return await PersonalDocumentsService.create(params)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                context.commit(SET_DOCUMENTS_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
            });
    },
    async [UPDATE_PERSONAL_DOCUMENT](context, payload) {
        return await PersonalDocumentsService.update(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
            context.commit(SET_DOCUMENTS_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
        });
    },    
    async [DELETE_GENERAL_DOCUMENT](context, slug) {
        return await GeneralDocumentsService.delete(slug)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                context.commit(SET_DOCUMENTS_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
            });
    },     
    async [DELETE_PERSONAL_DOCUMENT](context, slug) {
        return await PersonalDocumentsService.delete(slug)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                context.commit(SET_DOCUMENTS_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
            });
    }, 
    async [RESTORE_GENERAL_DOCUMENT](context, slug) {
        return await GeneralDocumentsService.restore(slug)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                context.commit(SET_DOCUMENTS_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
            });
    }, 
    async [DOWNLOAD_GENERAL_DOCUMENT](context, payload) {
        return await GeneralDocumentsService.downloadFile(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_DOCUMENTS_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
        });
    },     
    async [DOWNLOAD_PERSONAL_DOCUMENT](context, payload) {
        return await PersonalDocumentsService.downloadFile(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_DOCUMENTS_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
        });
    },     
    async [DELETE_GENERAL_DOCUMENT](context, slug) {
        return await GeneralDocumentsService.delete(slug)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                context.commit(SET_DOCUMENTS_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
            });
    },     
    async [DELETE_PERSONAL_DOCUMENT](context, slug) {
        return await PersonalDocumentsService.delete(slug)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                context.commit(SET_DOCUMENTS_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
            });
    },     
    [DOCUMENTS_RESET_STATE]({ commit }) {
        commit(RESET_DOCUMENTS_STATE);
    }

}

const mutations = {  
    [SET_COURSE_DOCUMENTS](state, coursedocuments) {
        state.coursedocuments = coursedocuments;
    },     
    [SET_GENERAL_DOCUMENTS](state, generaldocuments) {
        state.generaldocuments = generaldocuments;
    },  
    [SET_PERSONAL_DOCUMENTS](state, personaldocuments) {
        state.personaldocuments = personaldocuments;
    }, 
    [SET_INDIVIDUAL_GENERAL_DOCUMENT](state, individualgeneraldocument) {
        state.individualgeneraldocument = individualgeneraldocument;
    },  
    [SET_INDIVIDUAL_PERSONAL_DOCUMENT](state, individualpersonaldocument) {
        state.individualpersonaldocument = individualpersonaldocument;
    },            
    [SET_DOCUMENTS_ERROR](state, error) {
        state.documenterrors = error;
    }, 
    [RESET_DOCUMENTS_STATE](state) {
        Object.assign(state, initialState())
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};