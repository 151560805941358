import { ModulesService } from "@/common/api.service";
import { FETCH_MODULES, ADD_MODULE, DELETE_MODULE, FETCH_INDIVIDUAL_MODULE, UPDATE_MODULE, UPLOAD_MODULE, RESTORE_MODULE, MODULES_RESET_STATE } from "./actions.type";
import { SET_MODULES, SET_INDIVIDUAL_MODULE, SET_MODULE_ERROR, SET_UPLOAD_PROGRESS,RESET_MODULE_STATE } from "./mutations.type";

const initialState = () =>{
    return {
        modules: [],
        uploadProgress:0,
        individualmodule: {},
        moduleserrors: null,
    }
} ;

let state = initialState();

const getters = {
    modules(state) {
        return state.modules
    },
    uploadProgress(state){
        return state.uploadProgress
    },
    individualmodule(state) {
        return state.individualmodule
    },
};

const actions = {
    async [FETCH_MODULES](context, moduleSlug) {
        const { data } = await ModulesService.get(moduleSlug);
        context.commit(SET_MODULES, data.data);
        return data;
    },
    async [FETCH_INDIVIDUAL_MODULE](context, moduleid) {
        const { data } = await ModulesService.get(moduleid);
        context.commit(SET_INDIVIDUAL_MODULE, data.data);
        return data;        
    },
    async [ADD_MODULE](context, payload) {
        return await ModulesService.create(payload, '')
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_MODULE_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
        });
    }, 
    async [UPDATE_MODULE](context, payload) {
        return await ModulesService.update(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_MODULE_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
        });
    },
    async [UPLOAD_MODULE](context, payload) {
        return await ModulesService.uploadModule(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_MODULE_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
        });
    },
    async [DELETE_MODULE](context, slug) {
        return await ModulesService.delete(slug)
            .then(({ data }) => {
                return data
            })
            .catch(error => {
                context.commit(SET_MODULE_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
            });
    },
    async [RESTORE_MODULE] (context, id) {
        return await ModulesService.restore(id)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
            context.commit(SET_MODULE_ERROR, (error.response.data.hasOwnProperty('errors')? error.response.data.errors   : error.response.data.message));
        });
    }, 
    [MODULES_RESET_STATE]({ commit }) {
        commit(RESET_MODULE_STATE);
    }
};

const mutations = {
    [SET_MODULE_ERROR](state, error) {
        state.moduleserrors = error;
    },    
    [SET_MODULES](state, modules) {
        state.modules = modules;
    },
    [SET_UPLOAD_PROGRESS](state, progress) {
        state.uploadProgress = progress
    },    
    [SET_INDIVIDUAL_MODULE](state, module) {
        state.individualmodule = module;
    },
    [RESET_MODULE_STATE](state) {
        Object.assign(state, initialState())
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};