export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const CHECK_AUTH = 'checkAuth'
export const AUTH_RESET_STATE = 'AuthResetState'
export const FETCH_MODULES = 'fetchModules'
export const FETCH_INDIVIDUAL_MODULE = 'fetchIndividualModule'
export const ADD_MODULE = 'addModule'
export const UPDATE_MODULE = 'updateModule'
export const UPLOAD_MODULE = 'uploadModule'
export const DELETE_MODULE = 'deleteModule'
export const RESTORE_MODULE = 'restoreModule'

export const MODULES_RESET_STATE = 'resetModulesState'
export const FETCH_COURSES = 'fetchCourses'
export const FETCH_INDIVIDUAL_COURSE = 'fetchIndividualCourse'
export const ADD_COURSE = 'addCourse'
export const UPDATE_COURSE = 'updateCourse'

export const FETCH_COURSE_VERSIONS = 'fetchCourseVersions'
export const FETCH_INDIVIDUAL_COURSE_CONFIG = 'fetchIndividualCourseConfig'
export const ADD_COURSE_VERSIONS = 'addCourseVersion'
export const UPDATE_COURSE_VERSION = 'editCourseVersion'

export const COURSE_RESET_STATE = 'resetCourseState'
export const FETCH_COMPANIES = 'fetchCompanies'
export const FETCH_AUTHORS = 'fetchAuthors'
export const RESTORE_AUTHOR = 'restoreAuthor'

export const FETCH_PAGES    =   'fetchPages'
export const FETCH_LANGUAGES    =   'fetchLanguages'
export const FETCH_DOCUMENTS    =   'fetchDocuments'
export const DOWNLOAD_GENERAL_DOCUMENT  =   'downloadGeneralDocument'
export const DOWNLOAD_PERSONAL_DOCUMENT  =   'downloadPersonalDocument'
export const FETCH_INDIVIDUAL_AUTHOR = 'fetchIndividualAuthor'
export const FETCH_INDIVIDUAL_PAGE  =   'fetchIndividualPage'
export const FETCH_ALL_INVOICES = 'fetchAllInvoices'
export const FETCH_INDIVIDUAL_COMPANY = 'fetchIndividualCompany'
export const FETCH_GENERAL_DOCUMENTS    =   'fetchGeneralDocuments'
export const FETCH_SECTION_HEADER_TAGS   =   'fetchSectionHeaderTags'
export const FETCH_PERSONAL_DOCUMENTS    =   'fetchPersonalDocuments'
export const FETCH_COURSE_DOCUMENTS    =   'fetchCourseDocuments'
export const FETCH_INDIVIDUAL_PERSONAL_DOCUMENT    =   'fetchIndividualPersonalDocument'
export const FETCH_INDIVIDUAL_GENERAL_DOCUMENT    =   'fetchIndividualGeneralDocument'
export const ADD_GENERAL_DOCUMENT    =   'addGeneralDocument'
export const UPDATE_GENERAL_DOCUMENT    =   'updateGeneralDocument'
export const ADD_PERSONAL_DOCUMENT    =   'addPersonalDocument'
export const UPDATE_PERSONAL_DOCUMENT    =   'updatePersonalDocument'
export const DELETE_GENERAL_DOCUMENT    =   'deleteGeneralDocument'
export const RESTORE_GENERAL_DOCUMENT    =   'restoreGeneralDocument'
export const DELETE_PERSONAL_DOCUMENT    =   'deletePersonalDocument'
export const ADD_DOCUMENT   =   'addDocument'
export const ADD_COMPANY = 'addCompany'
export const ADD_AUTHOR = 'addAuthor'
export const ADD_PAGE = 'addPage'
export const UPDATE_AUTHOR = 'updateAuthor'
export const UPDATE_PAGE    =   'updatePage'
export const UPDATE_COMPANY = 'updateCompany'
export const DELETE_AUTHOR = 'deleteAuthor'
export const DELETE_PAGE = 'deletePage'
export const DELETE_DOCUMENT = 'deleteDocument'
export const STRIPE_RESET_STATE = 'resetStripeState'
export const AUTHOR_RESET_STATE = 'resetAuthorState'
export const PAGES_RESET_STATE  =   'resetPagesState'
export const LANGUAGES_RESET_STATE  =   'resetLanguagesState'
export const COMPANIES_RESET_STATE = 'resetCompaniesState'
export const DOCUMENTS_RESET_STATE = 'resetDocumentsState'
export const FETCH_PRODUCTS_ID  =   'fetchProductsID'
export const FETCH_PLANS  =   'fetchPlans'
export const FETCH_VOUCHER_PRODUCTS_ID  =   'fetchVoucherProductsID'
export const RESET_SECTION_HEADERS_STATE  =   'resetSectionHeaderState'
export const ADD_SECTION_HEADER  =   'createSectionHeader'
export const FETCH_VIDEO_TUTORIAL_DATA = 'fetchVideoTutorialData'
export const FETCH_INDIVIDUAL_VIDEO_TUTORIAL_DATA = 'fetchIndividualVideoTutorialData'
export const CREATE_VIDEO_TUTORIAL_DATA = 'createVideoTutorialData'
export const UPDATE_VIDEO_TUTORIAL_DATA = 'updateVideoTutorialData'
export const VIDEO_TUTORIAL_STATE_RESET  =   'VideoTutorialStatereset'
export const DELETE_VIDEO_TUTORIAL = 'deleteVideoTutorial'
export const FETCH_CERTIFICATE_TEMPLATE = 'fetchCertificateTemplate'
export const FETCH_INDIVIDUAL_CERTIFICATE_TEMPLATE = 'fetchIndividualCertificateTemplate'
export const CREATE_CERTIFICATE_TEMPLATE = 'createCertificateTempate'
export const UPDATE_CERTIFICATE_TEMPLATE = 'updateCertificateTemplate'
export const DELETE_CERTIFICATE_TEMPLATE = 'deleteCertificateTemplate'
export const CERTIFICATE_RESET_STATE  =   'resetCertificateTemplate'
export const PREVIEW_CERTIFICATE_TEMPLATE = 'previewCertificateTempalte'
export const FETCH_TEAMS = 'fetchTeams'
export const ADD_TEAM = 'addTeam'
export const UPDATE_TEAM = 'updateTeam'
export const DELETE_TEAM = 'deleteTeam'
export const FETCH_INDIVIDUAL_TEAM = 'fetchIndividualTeam'
export const TEAM_RESET_STATE = 'resetTeamState'

export const FETCH_TUTOR_PRODUCTS_ID = 'fetchTutorProductsId'
export const FETCH_TUTOR_PLANS = 'fetchTutorPlans'
export const FETCH_TUTOR_VOUCHER_PRODUCTS_ID = 'fetchTutorVoucherProductsId'

export const FETCH_TUTOR_PRODUCTS = 'fetchTutorProducts'
export const DELETE_TUTOR_PRODUCT = 'deleteTutorProduct'
export const ADD_TUTOR_PRODUCT = 'addTutorProduct'
export const UPDATE_TUTOR_PRODUCT = 'updateTutorProduct'
export const FETCH_INDIVIDUAL_TUTOR_PRODUCT = 'fetchIndividualTutorProduct'
export const TUTOR_PRODUCT_RESET_STATE = 'TutorProductResetState'

export const MICROSOFTLOGIN = 'microsoftLogin'
export const MICROSOFTCALLBACK = 'microsoftCallback'
export const MICROSOFTCALENDARACCESS = 'microsoftCalendarAccess'
export const MICROSOFTCALENDARCALLBACK = 'microsoftCalendarCallback'

export const FETCH_USERS = 'fetchUsers'
export const ADD_USER = 'addUser'
export const UPDATE_USER = 'updateUser'
export const DELETE_USER = 'deleteUser'
export const FETCH_INDIVIDUAL_USER = 'fetchIndividualUser'
export const USER_RESET_STATE = 'resetUserState'
export const UPDATE_USER_STATUS = 'updateUserStatus'
export const UPDATE_CUR_USER_LANG = 'updatecurrentUserLanguage'
export const FETCH_CALENDAR_USERS = 'fetchCalendarUsers'
export const FETCH_CALENDAR_ADMINS = 'fetchCalendarAdmins'
export const DELETE_CALENDAR_ADMIN = 'deleteCalendarAdmin'

export const FETCH_QUESTION = 'fetchQuestion'
export const FETCH_INDIVIDUAL_QUESTION = 'fetchIndividualQuestion'
export const ADD_QUESTION = 'addQuestion'
export const UPDATE_QUESTION = 'updateQuestion'
export const DELETE_QUESTION = 'deleteQuestion'
export const QUESTIONS_RESET_STATE = 'reseQuestionsState'
export const DELETE_ANSWER = 'deleteAnswer'

export const FETCH_SURVEY = 'fetchSurvey'
export const FETCH_INDIVIDUAL_SURVEY = 'fetchIndividualSurvey'
export const ADD_SURVEY = 'addSurvey'
export const UPDATE_SURVEY = 'updateSurvey'
export const DELETE_SURVEY = 'deleteSurvey'
export const FETCH_DAILY_SURVEY = 'fetchDailySurvey'
export const FETCH_OPEN_SURVEYS = 'fetchOpenSurvey'
export const SURVEY_RESET_STATE = 'resetSurveyState'

export const FETCH_USER_SURVEY = 'fetchUserSurvey'
export const DOWNLOAD_USER_SURVEY_CSV = 'downloadUserSurveyCSV'

export const FETCH_TEST_EXAM_QUESTION = 'fetchTestExamQuestion'
export const FETCH_INDIVIDUAL_TEST_EXAM_QUESTION = 'fetchIndividualTestExamQuestion'
export const ADD_TEST_EXAM_QUESTION = 'addTestExamQuestion'
export const UPDATE_TEST_EXAM_QUESTION = 'updateTestExamQuestion'
export const DELETE_TEST_EXAM_QUESTION = 'deleteTestExamQuestion'
export const TEST_EXAM_QUESTIONS_RESET_STATE = 'reseTestExamQuestionsState'
export const DELETE_TEST_EXAM_ANSWER = 'deleteTestExamAnswer'
export const RESTORE_TEST_EXAM_QUESTION = 'restoreTestExamQuestion'
export const FETCH_TEST_EXAM_QUESTION_TOPIC = 'fetchTestExamQuestionTopic'
export const ADD_TEST_EXAM_QUESTION_TOPIC_DATA = 'addTestExamQuestionTopicData'

export const FETCH_TEST_EXAM = 'fetchTestExam'
export const FETCH_INDIVIDUAL_TEST_EXAM = 'fetchIndividualTestExam'
export const ADD_TEST_EXAM = 'addTestExam'
export const UPDATE_TEST_EXAM = 'updateTestExam'
export const DELETE_TEST_EXAM = 'deleteTestExam'
export const RESTORE_TEST_EXAM = 'restoreTestExam'
export const FETCH_DAILY_TEST_EXAM = 'fetchDailyTestExam'
export const FETCH_OPEN_TEST_EXAMS = 'fetchOpenTestExam'
export const TEST_EXAM_RESET_STATE = 'resetTestExamState'
export const REMOVE_TEST_FROM_MODULE = 'removeTestfromModule'

export const FETCH_QUESTION_TOPIC = 'fetchQuestionTopic'
export const FETCH_INDIVIDUAL_QUESTION_TOPIC = 'fetchIndividualQuestionTopic'
export const ADD_QUESTION_TOPIC = 'addQuestionTopic'
export const UPDATE_QUESTION_TOPIC = 'updateQuestionTopic'
export const DELETE_QUESTION_TOPIC = 'deleteQuestionTopic'
export const QUESTION_TOPIC_RESET_STATE = 'reseQuestionTopicState'

export const FETCH_EXERCISES = 'fetchExercise'
export const ADD_EXERCISE = 'addExercise'
export const DELETE_EXERCISE = 'deleteExercise'
export const FETCH_INDIVIDUAL_EXERCISE = 'fetchIndividualExercise'
export const UPDATE_EXERCISE = 'UpdateExercise'
export const RESTORE_EXERCISE = 'restoreExercise'
export const EXERCISE_RESET_STATE = 'resetExerciseState'
export const DOWNLOAD_EXERCISE_TEMPLATE = 'downloadExerciseTemplate'

export const FETCH_REPORT = 'fetchaxpiReport'
export const ADD_REPORT = 'addaxpiReport'
export const UPDATE_REPORT = 'updateaxpiReport'
export const DELETE_REPORT = 'deleteaxpiReport'
export const FETCH_INDIVIDUAL_REPORT = 'fetchIndividualaxpiReport'
export const REPORT_RESET_STATE = 'axpiReportresetState'