export default {
    "English" : "English",
    "German" : "German",
    "Username" : "Username",
    "Password" : "Password",
    "Login" : "Login",
    "Login with Office" : "Login with Office",
    "Username is required" : "Username is required",
    "Password is required" : "Password is required",
    "Language" : "Language",
    "Actions" : "Actions",
    "Are you sure want to remove?" : "Are you sure want to remove?",
    "Close" : "Close",
    "No" : "No",
    "Yes" : "Yes",
    "Remove" : "Remove",
    "View Video" : "View Video",
    "View" : "View",
    "Search" : "Search",
    "Course" : "Course",
    "Status" : "Status",
    "Active" : "Active",
    "active" : "Active",
    "Draft" : "Draft",
    "External User" : "External User",
    "pending" : "Pending",
    "Inactive" : "Inactive",
    "Logout" : "Logout",
    "of" : "of",
    'No data available' : 'No data available',

    //Navbar
    "Dashboard" : "Dashboard",
    "Company" : "Company",
    "Entities" : "Entities",
    "Authors" : "Authors",
    "Admins" : "Admins",
    "Video tutorial" : "Video tutorial",
    "Documents" : "Documents",
    "Payments" : "Payments",
    "Test Center" : "Test Center",


    // Tutor Products
    "Tutor Products" : "Tutor Products",
    "Add Tutor Product" : "Add Tutor Product",
    "Edit Tutor Product" : "Edit Tutor Product",
    "Tutor Product Name" : "Tutor Product Name",
    "Tutor Product Name is required" : "Tutor Product Name is required",
    "Tutor Product Description" : "Tutor Product Description",
    "Tutor Product Description is required" : "Tutor Product Description is required",
    "Link to Team" : "Link to Team",
    "Team is required" : "Team is required",
    "Tutor Product Photo" : "Tutor Product Photo",

    // Courses
    "Courses" : "Courses",
    "Domain" : "Domain",
    "Add Course" : "Add Course",
    "Course name" : "Course name",
    "Course name is required" : "Course name is required",
    "Tag Line" : "Tag Line",
    "Course description is required" : "Course description is required",
    "Abbreviation" : "Abbreviation",
    "Abbreviation is required" : "Abbreviation is required",
    "Language is required" : "Language is required",
    "Author" : "Author",
    "Certificate description" : "Certificate description",
    "Certificate description is required" : "Certificate description is required",
    "Glossary" : "Glossary",
    "What Will You Learn?" : "What Will You Learn?",
    "Requirements" : "Requirements",
    "Long Description" : "Long Description",
    "Course image" : "Course image",
    "Course status" : "Course status",
    "Course status is required" : "Course status is required",
    "Course domain" : "Course domain",
    "File name" : "File name",
    "Add certifications" : "Add certifications",
    "Certificate name" : "Certificate name",
    "Select certificate" : "Select certificate",
    "Font" : "Font",
    "Font size" : "Font size",
    "Preview" : "Preview",
    "Font is required" : "Font is required",
    "Font size is required" : "Font size is required",
    "Course Id" : "Course Id",
    "Internal Description" : "Internal Description",
    "Course Versions" : "Course Versions",
    "Course ID:" : "Course ID: ",
    "Course Version Id" : "Course Version Id",
    "Course Version ID:" : "Course Version ID:",
    "Created On" : "Created On",
    "Course Subscription Ends In" : "Course Subscription Ends In",
    "Course Modules" : "Course Modules",
    "Test" : "Test",
    "No exams attached" : "No exams attached",
    "No survey Attached" : "No survey Attached",
    "Mandatory Exercises" : "Mandatory Exercises",
    "No Mandatory Exercise attached" : "No Mandatory Exercise attached",
    "Training Exercises" : "Training Exercises",
    "No Training Exercise attached" : "No Training Exercise attached",
    "Events" : "Events",
    "No Events attached" : "No Events attached",
    "General Documents" : "General Documents",
    "No General Documents attached" : "No General Documents attached",

    // Modules
    "Modules" : "Modules",
    "Title" : "Title",
    "Add Module" : "Add Module",
    "Module Title" : "Module Title",
    "Module Title is required" : "Module Title is required",
    "Thumbnail" : "Thumbnail",
    "Thumbnail is required" : "Thumbnail is required",
    "Edit Module" : "Edit Module",
    "Module domain" : "Module domain",
    "ADD NEW/ HOTFIX MODULE" : "ADD NEW/ HOTFIX MODULE",
    "Select Module File" : "Select Module File",
    "Module File is required" : "Module File is required",
    "Module Type" : "Module Type",
    "Module Type is required" : "Module Type is required",
    "Module File Type" : "Module File Type",
    "Module File Type is required" : "Module File Type is required",
    "Module Version" : "Module Version",
    "Comments" : "Comments",
    "Upload" : "Upload",
    "Hotfix Version" : "Hotfix Version",
    "Course domain is required" : "Course domain is required",
    "Author name is required" : "Author name is required",
    "Author Description is required" : "Author Description is required",
    "Short Description" : "Short Description",
    "Displaying all modules here." : "Displaying all modules here.",

    // Companies
    "Companies" : "Companies",
    "Company Prefix" : "Company Prefix",
    "Account Type" : "Account Type",
    "Available Seats" : "Available Seats",
    "Add Company" : "Add Company",
    "Edit Company" : "Edit Company",
    "Company Pre-fix" : "Company Pre-fix",
    "Company Prefix is required" : "Company Prefix is required",
    "Company Name" : "Company Name",
    "Company Name is required" : "Company Name is required",
    "Admin Salutation" : "Admin Salutation",
    "Admin Salutation is required" : "Admin Salutation is required",
    "Admin Name" : "Admin Name",
    "Admin Name is required" : "Admin Name is required",
    "Admin Email" : "Admin Email",
    "Invalid Admin Email address" : "Invalid Admin Email address",
    "Admin Phone Number" : "Admin Phone Number",
    "Admin Phone Number is required" : "Admin Phone Number is required",
    "Available Seats is required" : "Available Seats is required",
    "Company Logo" : "Company Logo",
    "Company Color" : "Company Color",
    "Company domain" : "Company domain",
    "Company Language" : "Company Language",
    "Company Language is required" : "Company Language is required",
    "Payment Account" : "Payment Account",
    "Payment Account is required" : "Payment Account is required",
    "Company Address" : "Company Address",
    "Company Address is required" : "Company Address is required",
    "Personal Documents" : "Personal Documents",
    "Personal documents is required" : "Personal documents is required",
    "Equipment status" : "Equipment status",
    "Equipment status is required" : "Equipment status is required",
    "Task center status" : "Task center status",
    "Task center status is required" : "Task center status is required",
    "Show Inbox" : "Show Inbox",
    "Show Inbox is required" : "Show Inbox is required",
    "Show External Users" : "Show External Users",
    "External Users is required" : "External Users is required",
    "Feedback Emails" : "Feedback Emails",
    "Course feedback emails are required" : "Course feedback emails are required",
    "Daily stats" : "Daily stats",
    "Daily stats is required" : "Daily stats is required",
    "Show Statistics" : "Show Statistics",
    "Statistics Option is required" : "Statistics Option is required",
    "Inquiry Email" : "Inquiry Email",
    "Inquiry Email is required" : "Inquiry Email is required",
    "Login Options" : "Login Options",
    "Login Option is required" : "Login Option is required",
    "Show Quiz" : "Show Quiz",
    "Show Quiz is required" : "Show Quiz is required",
    "Selected Courses" : "Selected Courses",
    "Course Products" : "Course Products",
    "Course Product Pricing Plans" : "Course Product Pricing Plans",
    "Has Voucher Products?" : "Has Voucher Products?",
    "Voucher Products" : "Voucher Products",
    "Voucher Product Pricing Plan" : "Voucher Product Pricing Plan",
    "Applied Voucher Product Pricing Plan" : "Applied Voucher Product Pricing Plan",
    "Has Sort?" : "Has Sort?",
    "Search Modules" : "Search Modules",
    "Has Tutor Product" : "Has Tutor Product",
    "Tutor Product" : "Tutor Product",
    "Selected Tutor Products" : "Selected Tutor Products",
    "Tutor Product Pricing Plan" : "Tutor Product Pricing Plan",
    "STAGING" : "STAGING",
    "PRODUCTION" : "PRODUCTION",
    "Mr" : "Mr",
    "Ms" : "Ms",
    "Mrs" : "Mrs",
    "Payment Account and Account type will be locked, Are you sure you want to proceed?" : "Payment Account and Account type will be locked, Are you sure you want to proceed?",
    "Calendar User ID" : "Calendar User ID",
    "Object ID of User in Microsoft 365 That is responsible for adding new calendars" : "Object ID of User in Microsoft 365 That is responsible for adding new calendars",
    "Object ID of Microsoft 365 User" : "Object ID of Microsoft 365 User",
    "Calendar Users" : "Calendar Users",

    // Authors
    "Author Name" : "Author Name",
    "Add Author" : "Add Author",
    "Edit Author" : "Edit Author",
    "Author Name is required" : "Author Name is required",
    "Author Information" : "Author Information",
    "Author Information is required" : "Author Information is required",
    "Author Photo" : "Author Photo",

    // Teams
    "Teams" : "Teams",
    "Team Name" : "Team Name",
    "Contact" : "Contact",
    "Website" : "Website",
    "Add Tutor Team" : "Add Tutor Team",
    "Edit Tutor Team" : "Edit Tutor Team",
    "Team Name is required" : "Team Name is required",
    "Team Description" : "Team Description",
    "Team Description is required" : "Team Description is required",
    "Team Contact Info" : "Team Contact Info",
    "Contact Info is required" : "Contact Info is required",
    "Website is required" : "Website is required",
    "User Booking Details" : "User Booking Details",
    "User Booking Details is required to send email" : "User Booking Details is required to send email",
    "Booking Emails" : "Booking Emails",
    "Team Photo" : "Team Photo",

    // Admins
    "Salutation" : "Salutation",
    "Name" : "Name",
    "User Name" : "User Name",
    "Email" : "Email",
    "Job Position" : "Job Position",
    "Phone Number" : "Phone Number",
    "Phone No" : "Phone No",
    "Add Admin" : "Add Admin",
    "Edit Admin" : "Edit Admin",
    "Salutation is required (eg Mr)" : "Salutation is required (eg Mr)",
    "Name is required" : "Name is required",
    "Email is required" : "Email is required",
    "Job Position is required" : "Job Position is required",
    "Phone Number is required" : "Phone Number is required",
    "Authorize Calendar User" : "Authorize Calendar User",
    "You do not have enough permissions. Please contact administrator" : "You do not have enough permissions. Please contact administrator",
    "Calendar Admins" : "Calendar Admins",
    "*You need to have appropriate permissions to link account" : "*You need to have appropriate permissions to link account",

    // Pages
    "Pages" : "Pages",
    "Order" : "Order",
    "Page slug" : "Page slug",
    "Add Page" : "Add Page",
    "Edit Page" : "Edit Page",
    "Page title" : "Page title",
    "Page title is required" : "Page title is required",
    "Page slug is required" : "Page slug is required",
    "Icon code" : "Icon code",
    "Icon is required" : "Icon is required",
    "Order is required" : "Order is required",
    "Shortcodes" : "Shortcodes",
    "Company address" : "Company address",
    "Company Url" : "Company Url",
    "Admin Phone" : "Admin Phone",
    "Admin name" : "Admin name",
    "Content" : "Content",
    "Click to view icons" : "Click to view icons",

    // Payments
    "Invoice Number" : "Invoice Number",
    "Date" : "Date",
    "Amount" : "Amount",


    // Video tutorials
    "Video tutorials" : "Video tutorials",
    "Video name" : "Video name",
    "Add Video tutorial" : "Add Video tutorial",
    "Edit Video tutorial" : "Edit Video tutorial",
    "Route" : "Route",
    "Link" : "Link",
    "Route is required" : "Route is required",
    "Video link is required" : "Video link is required",


    //General documents
    "General documents" : "General documents",
    "Certifications" : "Certifications",
    "Type" : "Type",
    "Add General document" : "Add General document",
    "Edit General document" : "Edit General document",
    "General document name" : "General document name",
    "General document name is required" : "General document name is required",
    "Type of documents" : "Type of documents",
    "Document type is required" : "Document type is required",
    "Direct link" : "Direct link",
    "Section header" : "Section header",
    "Search By Section Header" : "Search By Section Header",
    "Add Tag" : "Add Tag",
    "Add" : "Add",
    "Type is required" : "Type is required",
    "Description" : "Description",
    "Description is required" : "Description is required",
    "Click to copy link" : "Click to copy link",
    "Click to copy direct link" : "Click to copy direct link",
    "Upload date" : "Upload date",
    "Instruction" : "Instruction",
    "Standard" : "Standard",
    "Procedure" : "Procedure",
    "Manual" : "Manual",
    "Audit" : "Audit",
    "QM-System" : "QM-System",
    "Learning resource" : "Learning resource",
    "Add Documents" : "Add Documents",
    "Add Certifications" : "Add Certifications",
    "Download PDF" : "Download PDF",
    "Hidden until course is passed" : "Hidden until course is passed",
    "Displaying all documents here." : "Displaying all documents here.",
    "Displaying all deleted documents here." : "Displaying all deleted documents here.",
    "Show all Documents" : "Show all Documents",
    "Show all Archived Documents" : "Show all Archived Documents",

    'Question': 'Question',
    'Questions': 'Questions',
    'Add Question' : 'Add Question',
    'Edit Question' : 'Edit Question',
    'Question id' : 'Question id',
    'Question name is required' : 'Question name is required',
    'Question text is required' : 'Question text is required',
    'Question help text is required': 'Question help text is required',
    'At least one Question Topic is required': 'At least one Question Topic is required',
    'Question Type is required': 'Question Type is required',
    'The Topic ' : 'The Topic',
    ' is already added. Please select from the list or add a new topic.' : 'is already added. Please select from the list or add a new topic.',
    'Answers' : 'Answers',
    'Answer' : 'Answer',
    'Question name' : 'Question name',
    'Question text' : 'Question text',
    'Question Help Text' : 'Question Help Text',
    'Question Type' : 'Question Type',
    'Question Topic' : 'Question Topic',
    'Add Topic' : 'Add Topic',
    'Points' : 'Points',
    'Answer is required' : 'Answer is required',
    "Add New Question" : "Add New Question",
    "Add Question (Copied)" : "Add Question (Copied)",
    "Please Make necessary changes" : "Please Make necessary changes",
    "Question identifier" : "Question identifier",
    "Add Topic Description" : "Add Topic Description",
    "Displaying all deleted questions here." : "Displaying all deleted questions here.",

    "Add Question Topic" : "Add Question Topic",
    "Edit Question Topic" : "Edit Question Topic",
    "Question Topic name" : "Question Topic name",
    "Displaying all question topics here." : "Displaying all question topics here.",

    "Survey" : "Survey",
    "Surveys" : "Surveys",
    "Survey Results" : "Survey Results",
    "Start Date" : "Start Date",
    "End Date" : "End Date",
    "Download CSV" : "Download CSV",
    "Answered Questions" : "Answered Questions",
    "Course Name" : "Course Name",
    "Survey Name" : "Survey Name",
    "Created At" : "Created At",
    "Add Survey" : "Add Survey",
    "Edit Survey" : "Edit Survey",
    "Survey name" : "Survey name",
    "Survey Description" : "Survey Description",
    "Select Questions" : "Select Questions",
    "Survey image" : "Survey image",
    "Survey Language" : "Survey Language",
    "Questions Selected" : "Questions Selected",
    "Survey contains a total of " : "Survey contains a total of ",
    "questions" : "questions",
    "Order Index" : "Order Index",
    "Expires On" : "Expires On",
    "SurveyAnswers" : "SurveyAnswers",

    "Exam Test Questions" : "Exam Test Questions",
    "Question Topics" : "Question Topics",
    "Displaying all questions from exam/tests here." : "Displaying all questions from exam/tests here.",
    "Show all Questions" : "Show all Questions",
    "Show all Archived" : "Show all Archived",
    "Question Topic id" : "Question Topic id",
    "single-select" : "Single-Select",
    "multiple-select" : "Multiple-Select",
    "text" : "Text",
    "rating" : "Rating",
    "Please fill Question Topic and Question Topic Description fields" : "Please fill Question Topic and Question Topic Description fields",

    "Tests" : "Tests",
    "Displaying all tests here." : "Displaying all tests here.",
    "Displaying all deleted tests here." : "Displaying all deleted tests here.",
    "Show all Tests" : "Show all Tests",
    "Test id" : "Test id",
    "Add New Test" : "Add New Test",
    "Add Test (Copied)" : "Add Test (Copied)",
    "Please Make necessary changes to this Test" : "Please Make necessary changes to this Test",
    "Test name" : "Test name",
    "Test name is required" : "Test name is required",
    "Passing Percent(%)" : "Passing Percent(%)",
    "Passing Percent is required" : "Passing Percent is required",
    "Has Randomized Subset" : "Has Randomized Subset",
    "Randomized Question Count" : "Randomized Question Count",
    "Timeout (In minutes)" : "Timeout (In minutes)",
    "Time Limit (In minutes)" : "Time Limit (In minutes)",
    "Message 1" : "Message 1",
    "Message 2" : "Message 2",
    "Message 3" : "Message 3",
    "Enter a message when the user passes for the first time" : "Enter a message when the user passes for the first time",
    "Enter a message when the user has failed previously and fails again. The user's most recent test score is compared to their last test score and displayed." : "Enter a message when the user has failed previously and fails again. The user's most recent test score is compared to their last test score and displayed.",
    "Enter a message when the user has failed previously and now passes. The user's most recent test score is compared to their last test score and displayed." : "Enter a message when the user has failed previously and now passes. The user's most recent test score is compared to their last test score and displayed.",
    "Please enter a message when the user passes for the first time" : "Please enter a message when the user passes for the first time",
    "Please enter a message when the user has failed previously and fails again. The user's most recent test score is compared to their last test score and displayed." : "Please enter a message when the user has failed previously and fails again. The user's most recent test score is compared to their last test score and displayed.",
    "Please enter a message when the user has failed previously and now passes. The user's most recent test score is compared to their last test score and displayed." : "Please enter a message when the user has failed previously and now passes. The user's most recent test score is compared to their last test score and displayed.",
    "View Test" : "View Test",
    "Message when the user passes the first time" : "Message when the user passes the first time",
    "Message when the user has failed previously and fails again. The user’s most recent test score is compared to their last test score and displayed" : "Message when the user has failed previously and fails again. The user’s most recent test score is compared to their last test score and displayed",
    "Message when the user has failed previously and now passes. The user’s most recent test score is compared to their last test score and displayed" : "Message when the user has failed previously and now passes. The user’s most recent test score is compared to their last test score and displayed",
    'contains a total of ' : 'contains a total of ',
    ' Question. Out of which ' : ' Question. Out of which ',
    ' Questions. Out of which ' : ' Questions. Out of which ',
    ' Question will be shown, ' : ' Question will be shown, ',
    ' Questions will be shown, ' : ' Questions will be shown, ',
    ' is a Fixed Question and ' : ' is a Fixed Question and ',
    ' are Fixed Questions and ' : ' are Fixed Questions and ',
    ' no ' : ' no ',
    ' Question is randomized. ' : ' Question is randomized. ',
    ' Questions are randomized. ' : ' Questions are randomized. ',
    'YES' : 'YES',
    'NO' : 'NO',
    "Fixed Question" : "Fixed Question",
    "Question Name" : "Question Name",
    "Open All Answers" : "Open All Answers",
    'Close All' : 'Close All',
    "Points are shown in a blue bage icon (once a question is opened)" : "Points are shown in a blue bage icon (once a question is opened)",

    "Exams" : "Exams",
    "Show all Exams" : "Show all Exams",
    "Show all Archived Exams" : "Show all Archived Exams",
    "Displaying all deleted exams here" : "Displaying all deleted exams here",
    "Displaying all exams here." : "Displaying all exams here.",
    "Exam id" : "Exam id",
    "Add New Exam" : "Add New Exam",
    "Add Exam (Copied)" : "Add Exam (Copied)",
    "Please Make necessary changes to this Exam" : "Please Make necessary changes to this Exam",
    "Exam name" : "Exam name",
    "Enter a message when the user passes the Exam" : "Enter a message when the user passes the Exam",
    "Enter a message when the user fails the Exam" : "Enter a message when the user fails the Exam",
    "View Exam" : "View Exam",
    "Exam Name" : "Exam Name",
    "Pass Message" : "Pass Message",
    "Message when the user has passed the Exam" : "Message when the user has passed the Exam",
    "Fail Message" : "Fail Message",

    //Exercise
    "Exercises" : "Exercises",
    "Displaying all exercises here." : 'Displaying all exercises here.',
    "Displaying all deleted exercises here." : "Displaying all deleted exercises here.",
    "Add Exercise" : "Add Exercise",
    "Edit Exercise" : "Edit Exercise",
    "Exercise Name" : "Exercise Name",
    "Exercise Name is required" : "Exercise Name is required",
    "Exercise description is required" : "Exercise description is required",
    "Exercise Image" : "Exercise Image",
    "Exercise Image is required" : "Exercise Image is required",
    "First Page Instructions" : "First Page Instructions",
    "Exercise Instructions" : "Exercise Instructions",
    "Add resource" : "Add resource",
    "Resource is required" : "Resource is required",
    "Upload resource" : "Upload resource",
    "Result Page Instructions" : "Result Page Instructions",
    "View Exercise" : "View Exercise",
    "Exercise Resource" : "Exercise Resource",
    "Download Exercise" : "Download Exercise",

    //Calendar
    "Calendar event name that is required for a user to attend. Make sure you have this added in the name of the calendar event in microsoft calendar eg ##ARBEITEVENT##" : "Calendar event name that is required for a user to attend. Make sure you have this added in the name of the calendar event in microsoft calendar eg ##ARBEITEVENT##",
    "Event Key description for the user to prompt the user to join the event inorder to pass the course." : "Event Key description for the user to prompt the user to join the event inorder to pass the course.",
    "Calendar Event Key" : "Calendar Event Key",
    "Event Key Description" : "Event Key Description",
    "Downtime Calendar Name" : "Downtime Calendar Name",
    "Name of the Downtime Calendar" : "Name of the Downtime Calendar",
    "Course Expiry Calendar Name" : "Course Expiry Calendar Name",
    "Name of the Course Expiry Calendar" : "Name of the Course Expiry Calendar",
    "Course Expiry Event Description" : "Course Expiry Event Description",
    "Downtime Calendar name is required" : "Downtime Calendar name is required",
    "Course Expiry Calendar name is required" : "Course Expiry Calendar name is required",
    "Calendar User Object ID is required" : "Calendar User Object ID is required",

    "No tests attached" : "No tests attached",
    "Has Follow Up Questions?" : "Has Follow Up Questions?",
    "Select follow Up Question" : "Select follow Up Question",



};