import Vue from 'vue'
import Company from '../views/company/company.vue'
import VueRouter from 'vue-router'
import { CHECK_AUTH } from "@/store/actions.type"
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },  
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../views/dashboard/dashboard.vue'),   
    meta: { 
      requiresAuth: true
    }
  },  
  {
    path: "/login",
    name: "login",
    component: () => import("../views/authentication/login"),    
  },  
  {
    path: '/microsoft-auth/callback',
    name: 'microsoft-auth/callback',
    component: () => import('../views/authentication/MicrosoftLogin.vue'),
    meta: { 
      requiresAuth: false
    }
  },  
    {
    path: '/admins/microsoft-auth/callback',
    name: 'admins/microsoft-auth/callback',
    component: () => import('../views/authentication/MicrosoftCalendarAccess.vue'),
    meta: { 
      requiresAuth: true
    }
  },  
  {
    path: '/reporting-dashboard',
    name: 'reportingdashboard',
    component: () => import('../views/reporting/ReportingDashboard.vue'),   
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: "/reporting/add",
    name: "AddReporting",
    component: () => import("../views/reporting/addeditReporting.vue"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/reporting/edit/:id",
    name: "editReporting",
    component: () => import("../views/reporting/addeditReporting.vue"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/modules",
    name: "modules",
    component: () => import("../views/modules/modules"),
    meta: { 
      requiresAuth: true
    }    
  },   
  {
    path: "/modules/add",
    name: "AddModules",
    component: () => import("../views/modules/addeditModules"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/modules/edit/:id",
    name: "editmodules",
    component: () => import("../views/modules/addeditModules"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/modules/draft/:id",
    name: "draftmodules",
    component: () => import("../views/modules/addeditModules"),
    meta: { 
      requiresAuth: true
    }    
  },    
  {
    path: "/company",
    name: "company",
    component: Company,
    meta: { 
      requiresAuth: true
    }    
  },   
  {
    path: "/general-documents",
    name: "generaldocuments",
    component: () => import("../views/documents/general/Generaldocuments"),   
    meta: { 
      requiresAuth: true
    }    
  },
  {
    path: "/general-documents/add",
    name: "addgeneraldocuments",
    component: () => import("../views/documents/general/addeditGeneraldocuments"),   
    meta: { 
      requiresAuth: true
    }    
  },    
  {
    path: "/general-documents/edit/:id",
    name: "editgeneraldocuments",
    component: () => import("../views/documents/general/addeditGeneraldocuments"),   
    meta: { 
      requiresAuth: true
    }    
  },   
  {
    path: "/general-documents/draft/:id",
    name: "draftgeneraldocuments",
    component: () => import("../views/documents/general/addeditGeneraldocuments"),   
    meta: { 
      requiresAuth: true
    }    
  },   
  {
    path: "/certifications",
    name: "certificates",
    component: () => import("../views/documents/general/Certificates"),   
    meta: { 
      requiresAuth: true
    }    
  },
  {
    path: "/certifications/add",
    name: "addcertificates",
    component: () => import("../views/documents/general/addeditCertificates"),   
    meta: { 
      requiresAuth: true
    }    
  },    
  {
    path: "/certifications/edit/:id",
    name: "editcertificates",
    component: () => import("../views/documents/general/addeditCertificates"),   
    meta: { 
      requiresAuth: true
    }    
  },     
  {
    path: "/authors",
    name: "authors",
    component: () => import("../views/authors/authors"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/authors/draft/:id",
    name: "draftauthors",
    component: () => import("../views/authors/addeditAuthors"),
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/authors/add/",
    name: "addauthors",
    component: () => import("../views/authors/addeditAuthors"),
    meta: { 
      requiresAuth: true
    }    
  },     
  {
    path: "/teams",
    name: "teams",
    component: () => import("../views/teams/teams"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/teams/edit/:id",
    name: "editteams",
    component: () => import("../views/teams/addeditteams"),
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/teams/add/",
    name: "addteams",
    component: () => import("../views/teams/addeditteams"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/calendaradmins",
    name: "calendaradmins",
    component: () => import("../views/admins/calendaradmin"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/admins",
    name: "admins",
    component: () => import("../views/admins/admins"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/admins/edit/:id",
    name: "editadmins",
    component: () => import("../views/admins/addeditadmins"),
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/admins/add/",
    name: "addadmins",
    component: () => import("../views/admins/addeditadmins"),
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/tutor-products",
    name: "tutorproducts",
    component: () => import("../views/tutorProducts/tutorproducts"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/tutor-products/edit/:id",
    name: "edittutorproducts",
    component: () => import("../views/tutorProducts/addedittutorproducts"),
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/tutor-products/add/",
    name: "addtutorproducts",
    component: () => import("../views/tutorProducts/addedittutorproducts"),
    meta: { 
      requiresAuth: true
    }    
  },      
  {
    path: "/payments",
    name: "payments",
    component: () => import("../views/payments/payments"),
    meta: { 
      requiresAuth: true
    }    
  },    
  {
    path: "/company/add",
    name: "addcompany",
    component: () => import("../views/company/addeditCompany"),
    meta: { 
      requiresAuth: true
    }    
  },    
  {
    path: "/company/edit/:id",
    name: "editcompany",
    component: () => import("../views/company/addeditCompany"),
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/courses",
    name: "courses",
    component: () => import("../views/courses/coursesubscription"),    
    meta: { 
      requiresAuth: true
    } 
  },
  {
    path: "/course-versions/add/:courseid",
    name: "addcourses",
    component: () => import("../views/courses/addeditCourseVersions"),  
    meta: { 
      requiresAuth: true
    }   
  }, 
  {
    path: "/course-versions/draft/:courseid/:id",
    name: "draftcourses",
    component: () => import("../views/courses/addeditCourseVersions"), 
    meta: { 
      requiresAuth: true
    }     
  },
  {
    path: "/course-versions/edit/:courseid/:id",
    name: "editcourses",
    component: () => import("../views/courses/addeditCourseVersions"), 
    meta: { 
      requiresAuth: true
    }     
  },
  {
    path: "/course-versions/:id",
    name: "courseversion",
    component: () => import("../views/courses/courseVersion"),    
    meta: { 
      requiresAuth: true
    } 
  }, 
  {
    path: "/course-versions/view/:courseid/:id",
    name: "viewcourseversion",
    component: () => import("../views/courses/viewCourseVersion"),    
    meta: { 
      requiresAuth: true
    } 
  }, 
  {
    path: "/course-subscription/add",
    name: "addcoursesubscription",
    component: () => import("../views/courses/addeditCourseSubscription"),  
    meta: { 
      requiresAuth: true
    }   
  }, 
  {
    path: "/course-subscription/edit/:id",
    name: "editcoursesubscription",
    component: () => import("../views/courses/addeditCourseSubscription"), 
    meta: { 
      requiresAuth: true
    }     
  },
  {
    path: "/pages",
    name: "pages",
    component: () => import("../views/pages/pages"),  
    meta: { 
      requiresAuth: true
    }  
  },   
  {
    path: "/pages/add",
    name: "addpage",
    component: () => import("../views/pages/addeditPages"),  
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/pages/edit/:id",
    name: "editpage",
    component: () => import("../views/pages/addeditPages"),  
    meta: { 
      requiresAuth: true
    }    
  },    
  {
    path: "/video-tutorial",
    name: "videotutorial",
    component: () => import("../views/videotutorial/videotutorial"),    
    meta: { 
      requiresAuth: true
    }  
  }, 
  {
    path: "/video-tutorial/add",
    name: "addVideoTutorial",
    component: () => import("../views/videotutorial/addeditVideoTutorial"),    
    meta: { 
      requiresAuth: true
    }  
  },   
  
  {
    path: "/video-tutorial/edit/:id",
    name: "editVideoTutorial",
    component: () => import("../views/videotutorial/addeditVideoTutorial"),  
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/questions",
    name: "questions",
    component: () => import("../views/surveys/Questions"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/questions/edit/:id",
    name: "editquestions",
    component: () => import("../views/surveys/addeditQuestions"),
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/questions/add/",
    name: "addquestions",
    component: () => import("../views/surveys/addeditQuestions"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/surveys",
    name: "surveys",
    component: () => import("../views/surveys/Surveys"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/surveys/edit/:id",
    name: "editsurveys",
    component: () => import("../views/surveys/addeditSurveys"),
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/surveys/add/",
    name: "addsurveys",
    component: () => import("../views/surveys/addeditSurveys"),
    meta: { 
      requiresAuth: true
    }    
  },     
  {
    path: "/usersurveys",
    name: "usersurveys",
    component: () => import("../views/surveys/UserSurveys"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/usersurveys/view/:id",
    name: "viewusersurveys",
    component: () => import("../views/surveys/viewUserSurveys"),
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/tests-exams",
    name: "tests",
    component: () => import("../views/tests/Tests"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/tests/draft/:id",
    name: "drafttests",
    component: () => import("../views/tests/addeditTests"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/tests/add/",
    name: "addtests",
    component: () => import("../views/tests/addeditTests"),
    meta: { 
      requiresAuth: true
    }    
  },   
  {
    path: "/tests/view/:id",
    name: "viewtests",
    component: () => import("../views/tests/viewTestsExam"),
    meta: { 
      requiresAuth: true
    }    
  },    
  {
    path: "/testquestions",
    name: "testquestions",
    component: () => import("../views/tests/TestQuestions"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/testquestions/edit/:id",
    name: "edittestquestions",
    component: () => import("../views/tests/addeditTestQuestions"),
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/testquestions/draft/:id",
    name: "drafttestquestions",
    component: () => import("../views/tests/addeditTestQuestions"),
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/testquestions/add/",
    name: "addtestquestions",
    component: () => import("../views/tests/addeditTestQuestions"),
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/exams/draft/:id",
    name: "draftexams",
    component: () => import("../views/tests/addeditTests"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/exams/add/",
    name: "addexams",
    component: () => import("../views/tests/addeditTests"),
    meta: { 
      requiresAuth: true
    }    
  },   
  {
    path: "/exams/view/:id",
    name: "viewexams",
    component: () => import("../views/tests/viewTestsExam"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/question-topic",
    name: "question-topic",
    component: () => import("../views/tests/TestQuestions"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/question-topic/edit/:id",
    name: "editquestiontopic",
    component: () => import("../views/tests/addeditQuestionTopic"),
    meta: { 
      requiresAuth: true
    }    
  },   
  {
    path: "/question-topic/add/",
    name: "addquestiontopic",
    component: () => import("../views/tests/addeditQuestionTopic"),
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/exercises",
    name: "exercises",
    component: () => import("../views/exercises/Exercises"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/exercises/draft/:id",
    name: "draftexercises",
    component: () => import("../views/exercises/addeditExercises"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/exercises/add/",
    name: "addexercises",
    component: () => import("../views/exercises/addeditExercises"),
    meta: { 
      requiresAuth: true
    }    
  },   
  {
    path: "/exercises/view/:id",
    name: "viewexercises",
    component: () => import("../views/exercises/viewExercise"),
    meta: { 
      requiresAuth: true
    }    
  },    
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next) =>{
  if(!to.meta.requiresAuth){
    next()
  }
  else{
    store.dispatch(CHECK_AUTH).then(() =>{
      if(!['login'].includes(to.name) && !store.getters.isAuthenticated){
        next({name: 'login'})
      } else next()
    }) 
  }



});
    


export default router
