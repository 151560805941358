import { ReportingService } from "@/common/api.service";
import { FETCH_REPORT, DELETE_REPORT,ADD_REPORT, UPDATE_REPORT,FETCH_INDIVIDUAL_REPORT,REPORT_RESET_STATE } from "./actions.type";
import { SET_REPORT, SET_INDIVIDUAL_REPORT ,SET_REPORT_ERROR,RESET_REPORT_STATE } from "./mutations.type";
const initialState = () =>{
    return {  
        reportingdata: [],
        individualreportingdata:[],
        reportingerrors: null,
    }
};
export const state = initialState();
const getters = {
    reportingdata(state) {
        return state.reportingdata;
    },
    individualreportingdata(state) {
        return state.individualreportingdata;
    }    
};
const actions = {
    async [FETCH_REPORT](context) {
        const { data } = await ReportingService.get();
        context.commit(SET_REPORT, data.data);
        return data;
    },   
    async [FETCH_INDIVIDUAL_REPORT](context,slug) {
        const { data } = await ReportingService.get(slug);
        context.commit(SET_INDIVIDUAL_REPORT , data.data);
        return data;
    },       
    async [ADD_REPORT](context, payload) {
        return await ReportingService.create(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_REPORT_ERROR, error.response.data.errors);
        });
    },    
    async [UPDATE_REPORT](context, payload) {
        return await ReportingService.update(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_REPORT_ERROR, error.response.data.errors);
        });
    }, 
    async [DELETE_REPORT] (context, slug) {
        return await ReportingService.delete(slug)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_REPORT_ERROR, error.response.data.errors);
        });
    },   
    [REPORT_RESET_STATE]({ commit }) {
        commit(RESET_REPORT_STATE);
    }    
};
const mutations = {
    [SET_REPORT_ERROR](state, error) {
        state.reportingerrors = error;
    },      
    [SET_REPORT](state, reportingdata) {
        state.reportingdata = reportingdata;
    },
    [SET_INDIVIDUAL_REPORT ](state, reportingdata) {
        state.individualreportingdata = reportingdata;
    },    
    [RESET_REPORT_STATE](state) {
        Object.assign(state, initialState())
    }    
};
export default {
    state,
    getters,
    actions,
    mutations
};